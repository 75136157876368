<template>
  <!-- Prices -->
    <div style="width: 100%;">
        <b-overlay :show="tableOverlay" rounded="sm">
            <b-card>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Nombre del proveedor</th>
                            <th>Identificador</th>
                            <th>Numero de telefono</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(company, index) in companiesSeller" :key="company.companyId">
                            <td>
                                <b-form-input
                                        v-model="company.name"
                                />
                            </td>
                            <td>
                                <b-form-input
                                    disabled
                                    v-model="company.shortCompanyId"
                                />
                            </td>
                            <td>
                                <b-form-input
                                    v-model="company.phoneNumber"
                                />
                            </td>
                            <td>
                                <b-button
                                        v-if="false"
                                        variant="outline-danger"
                                        class="mt-0 mt-md-2"
                                        @click="removeItem(index)"
                                >
                                    <feather-icon
                                            icon="XIcon"
                                            class="mr-25"
                                    />
                                    <span>Eliminar</span>
                                </b-button>
                            </td>
                        </tr>
                        <tr>
                            <b-button
                                    variant="outline-primary"
                                    @click="addNewItem()"
                            >
                                <feather-icon
                                        icon="PlusIcon"
                                        class="mr-25"
                                />
                                <span>Agregar nueva compania</span>
                            </b-button>
                        </tr>
                        </tbody>
                    </table>
                    <div v-if="companiesSeller.length === 0" class="mt-2" style="text-align: center">
                        <h6 class="mb-2">No hay registros.</h6>
                    </div>
                </div>
                <div class="mt-2" style="text-align: center">
                    <b-button variant="primary" @click="changeCompaniesSellerApi()">
                        <feather-icon icon="SaveIcon" class="mr-50" />
                        Guardar cambios
                    </b-button>
                </div>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {BCard, BFormInput, BButton, BOverlay, BTab, BTabs, BFormGroup} from 'bootstrap-vue';
import utils from '@/utils/utils.js';
import vSelect from "vue-select";

export default {
    components: {
        BFormGroup,
        BTabs, BTab,
        BCard,
        BFormInput,
        BButton,
        BOverlay,
        vSelect,
    },
    data() {
        return {
            // Data
            user: JSON.parse(localStorage.getItem("user")),
            products: [],
            companiesSeller: [],

            // Props
            tableOverlay: true,
        };
    },
    created() {
        this.findCompaniesSellerApi()
    },
    methods: {
        findCompaniesSellerApi() {
            this.$axios
                .post(this.$services + 'manager/findCompaniesSellerApi', {
                    companyId: this.user.companyId,
                })
                .then((res) => {
                    if (res.data) {
                        this.companiesSeller = res.data;
                    }
                })
                .finally(() => {
                    this.tableOverlay = false;
                });
        },
        changeCompaniesSellerApi() {
            this.tableOverlay = true;

            this.$axios
                .post(this.$services + 'manager/changeCompaniesSellerApi', {
                    companyId: this.user.companyId,
                    companiesSeller: this.companiesSeller
                })
                .then((res) => {
                    this.showToast('success', 'Lista de productos actualizados');
                })
                .finally(() => {
                    this.tableOverlay = false;
                    this.findCompaniesSellerApi()
                });
        },
        showToast(variant, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Notificación',
                    icon: 'BellIcon',
                    text,
                    variant,
                },
            });
        },
        addNewItem() {
            this.companiesSeller.push({
                createdByCompanyId: this.user.companyId,
                name: 'Nueva Compania',
            });
        },
        removeItem(index) {
            return
            const priceListProductId = priceListProducts[index].priceListProductId
            if (priceListProductId) {
                this.priceListProductIdsToRemove.push(priceListProductId)
            }
            priceListProducts.splice(index, 1);
        },
        removePriceList(priceListId, index) {
            if (priceListId) {
                this.removePriceListApi(priceListId)
            }
            this.priceLists.splice(index, 1);
        },
        removePriceListApi(priceListId) {
            this.$axios
                .post(this.$services + 'manager/removePriceListApi', {
                    priceListId
                })
                .then((res) => {
                    this.showToast('success', 'Lista eliminada');
                })
                .finally(() => {
                    this.findPriceListsApi();
                    this.tableOverlay = false;
                });
        },
    },
};
</script>
